<template>
  <div>
    <Hero />
    <Content />
  </div>
</template>

<script>
import Analytics from "@aws-amplify/analytics";
import Content from "@/components/Company/Content";
import Hero from "@/components/Company/Hero";

export default {
  name: "Company",
  components: {
    Content,
    Hero,
  },
  async created() {
    console.log("Analytics start:");
    let analyticsResponse = await Analytics.record(
      {
        data: {
          company: "thedocyard",
        },
        streamName: "graduated-analytics-DeliveryStream-bc4oe80TgrPa",
      },
      "AWSKinesisFirehose"
    );
    console.log("Analytics end:" + analyticsResponse);
  },
};
</script>
