<template>
  <ul>
    <li
      v-for="link in links"
      :key="link.type"
      class="mb-2"
    >
      <a
        :href="link.url"
        class="social-link"
      >
        <i :class="social[link.type]" /><span class="pl-2">{{
          link.title
        }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SocialLinks",
  props: {
    links: {
      //type: Array,
      required: true,
    },
  },
  data() {
    return {
      social: {
        blog: "fas fa-blog",
        facebook: "fab fa-facebook-square",
        link: "fas fa-link",
        linkedin: "fab fa-linkedin",
        twitter: "fab fa-twitter",
        stackoverflow: "fab fa-stack-overflow",
        github: "fab fa-github-square",
        bitbucket: "fab fa-bitbucket",
        instagram: "fab fa-instagram"
      },
    };
  },
  created() {
    console.log(this.links);
  },
};
</script>

<style scoped>
.social-link {
  @apply text-gray-700 text-xs;
}
.social-link:hover {
  @apply text-blue-400;
}

.social-link:active {
  @apply text-blue-400;
}
</style>
