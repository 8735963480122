var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-card", { staticClass: "job-card" }, [
    _c("div", { staticClass: "text-lg mb-2" }, [
      _vm._v(" " + _vm._s(_vm.title) + " ")
    ]),
    _c("p", { staticClass: "text-sm text-gray-700 m-0" }, [
      _vm._v(" " + _vm._s(_vm.location) + " ")
    ]),
    _c("p", { staticClass: "text-sm text-gray-700 pb-4 mt-0" }, [
      _vm._v(" " + _vm._s(_vm.workType) + " ")
    ]),
    _c(
      "a",
      { attrs: { href: _vm.link } },
      [
        _c("ui-button", { attrs: { theme: "primary", size: "xs" } }, [
          _vm._v(" Apply ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }