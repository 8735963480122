<template>
  <ui-card class="list-card ">
    <div class="text text-left mb-2 font-semibold" style="color: #03B3F0">{{ title }}</div>
    <p v-for="item in items" :key="item.value" class="text-sm font-light text-left text-gray-700 m-0">{{ item.value }}</p>
  </ui-card>
</template>

<script>
import UiCard from "../UI/UiCard/UiCard";

export default {
  name: "ListCard",
  components: { UiCard },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.job-card {

}
</style>
