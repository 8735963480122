<template>
  <div>
    <div class="w-full max-w-screen-lg mx-auto pb-4">
      <div class="flex justify-center -mt-20">
        <img
          :src="`/logos/${company.slug.toLowerCase()}.png`"
          class="h-32 w-32 rounded-full border border-1 shadow-large z-10 bg-white"
        >
      </div>
      <div
        class="grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 gap-8 my-8 px-3"
      >
        <div class="col-span-3">
          <markdown :content="company.blurb" />
        </div>
        <div class="col-span-1 mt-4">
          <social-links :links="content[company.slug].links" />
        </div>
      </div>
    </div>
    <div style="background-color: #f5f6f8;">
      <div
        class="grid xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-4 pt-12 max-w-screen-lg pb-4 gap-8 mx-auto px-3 lg:px-0"
      >
        <job-card
          v-for="job in content[company.slug].jobs"
          :key="job.title"
          v-bind="job"
          class="bg-white"
        />
      </div>
    </div>
    <div style="background-color: #f5f6f8;">
      <div class="w-full max-w-screen-lg mx-auto pt-1 pb-8">
        <div
          class="grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 gap-8 my-8"
        >
          <div class="md:col-span-3 bg-white px-6 pb-6 rounded">
            <markdown :content="company.content" />
          </div>
          <div class="md:col-span-1">
            <list-card
              title="Culture"
              :items="content[company.slug].culture"
              class="bg-white mb-10 w-full"
            />
            <list-card
              title="Skills"
              :items="content[company.slug].skills"
              class="bg-white mb-10 w-full"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "@aws-amplify/api";
import Markdown from "@/components/Markdown";
import { getCompany } from "@/graphql/queries";
import JobCard from "@/components/Cards/JobCard";
import ListCard from "@/components/Cards/ListCard";
import SocialLinks from "@/components/Social/SocialLinks";

export default {
  name: "Content",
  components: {
    JobCard,
    ListCard,
    Markdown,
    SocialLinks,
  },
  data() {
    return {
      company: {
        blurb: "",
        content: "",
        slug: ""
      },
      content: {
        canva: {
          links: [
        {
          type: "link",
          title: "canva.com",
          url: "https://canva.com",
        },
        {
          type: "linkedin",
          title: "LinkedIn",
          url: "https://www.linkedin.com/company/canva/",
        },
        {
          type: "instagram",
          title: "Instagram",
          url: "https://www.instagram.com/canva/",
        },
      ],
      jobs: [
        {
          id: "123456",
          title: "Graduate Software Engineer (Javascript  Frontend)",
          location: "Sydney, NSW",
          workType: "Fulltime",
          link: "https://www.canva.com/careers/jobs/sydney-graduate-software-engineer-javascript-frontend/"
        },
      ],
      culture: [
        { value: "Free meals" },
        { value: "Gym memberships" },
        { value: "Relocation" },
        { value: "Flexible workplace" },
        { value: "Learning & growth" },
      ],
      skills: [
        { value: "javascript" },
        { value: "typescript" },
        { value: "reactjs" },
        { value: "AWS" },
        { value: "OOP" },
        
      ],
    },
        swipejobs: {
          links: [
        {
          type: "link",
          title: "swipejobs.com",
          url: "https://swipejobs.com",
        },
        {
          type: "linkedin",
          title: "LinkedIn",
          url: "https://www.linkedin.com/company/swipejobs/",
        },
        {
          type: "instagram",
          title: "Instagram",
          url: "https://www.instagram.com/swipejobs/?hl=en",
        },
      ],
      jobs: [
        {
          id: "123456",
          title: "Graduate Software Developer",
          location: "Sydney, NSW",
          workType: "Fulltime",
          link: "https://swipejobs.breezy.hr/p/389eb1b8c3ef-graduate-software-developer"
        },
      ],
      culture: [
        { value: "Collaboration" },
        { value: "Free meals" },
        { value: "Salary continuance" },
        { value: "Celebrate our wins" },
        { value: "Accelerate your career" },
        { value: "Work with new technology" },
        { value: "Graduate programme" },
      ],
      skills: [
        { value: "JAVA" },
        { value: "reactjs" },
        { value: "AWS" },
        { value: "Kubernetes" },
        { value: "Docker" },
        { value: "Spark" },
        { value: "Scala" },
      ],
    },
      thedocyard: {links: [
        {
          type: "link",
          title: "thedocyard.com",
          url: "https://thedocyard.com.au",
        },
        {
          type: "linkedin",
          title: "LinkedIn",
          url: "https://www.linkedin.com/company/dealmanagement/",
        },
        {
          type: "twitter",
          title: "Twitter",
          url: "https://twitter.com/thedocyard_co",
        },
        {
          type: "facebook",
          title: "Facebook",
          url: "https://www.facebook.com/thedocyard.co/",
        },
      ],
      jobs: [
        {
          id: "123456",
          title: "Graduate Engineer",
          location: "Sydney, NSW",
          workType: "Fulltime",
        },
        {
          id: "123456",
          title: "Graduate UI/UX",
          location: "Sydney, NSW",
          workType: "Fulltime",
        },
        {
          id: "123456",
          title: "Graduate Data Scientest",
          location: "Sydney, NSW",
          workType: "Fulltime",
        },
        {
          id: "123456",
          title: "Graduate DevOps",
          location: "Sydney, NSW",
          workType: "Fulltime",
        },
      ],
      culture: [
        { value: "Collaborative over time zones" },
        { value: "Supportive environment" },
        { value: "Monthly team activties" },
        { value: "Diverse teams" },
        { value: "Innovation" },
      ],
      skills: [
        { value: ".NETCore" },
        { value: "reactjs" },
        { value: "Kubernetes" },
        { value: "Devops" },
        { value: "Terraform" },
      ],

        }
      },
      
      routeParam: this.$route.params.id,
    };
  },
  async created() {
    const c = await API.graphql({
      query: getCompany,
      variables: { slug: this.routeParam },
      authMode: "AWS_IAM",
    });
    this.company = c.data.company;
  },
};
</script>
