var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.links, function(link) {
      return _c("li", { key: link.type, staticClass: "mb-2" }, [
        _c("a", { staticClass: "social-link", attrs: { href: link.url } }, [
          _c("i", { class: _vm.social[link.type] }),
          _c("span", { staticClass: "pl-2" }, [_vm._v(_vm._s(link.title))])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }