<template>
  <span v-html="markdown" />
</template>

<script>
export default {
  name: "Markdown",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tags: {
        h1: [],
        h2: ["text-3xl", "font-semibold", "pt-3"],
        h3: ["text-2xl", "font-semibold", "pt-3", "mb-4"],
        h4: [],
        p: ["mt-5", "font-sans", "font-light", "text-sm"],
        a: ["underline"],
        bold: ["font-medium"],
        italic: ["italic"],
      },
    };
  },
  computed: {
    markdown() {
      let content = this.content;

content = content.replace(/^\s*\n\*/gm, '<ul class="pl-4 list-inside list-disc font-sans font-light text-sm">\n*');
  content = content.replace(/^(\*.+)\s*\n([^\*])/gm, '$1\n</ul>\n\n$2');
  content = content.replace(/^\*(.+)/gm, '<li>$1</li>');

      let lines = content.split(/(\n\n*)/g);
      let html = lines.map(this.parseBlock).join("");


      return html;
    },
  },

// https://www.bigomega.dev/markdown-parser
//https://eloquentjavascript.net/1st_edition/chapter6.html#p90fad98
/*

function escapeHTML(text) {
  var replacements = [[/&/g, "&amp;"], [/"/g, "&quot;"],
                      [/</g, "&lt;"], [/>/g, "&gt;"]];
  forEach(replacements, function(replace) {
    text = text.replace(replace[0], replace[1]);
  });
  return text;
}
*/

  // block
  // # h1
  // ## h2
  // ### h3
  // #### h4
  // ![]() image
  // * list

  // paragraphs
  // * italics
  // ** bold
  // []() anchor

  methods: {
    parseBlock(block) {
      switch (block[0]) {
        case "#": // header
          return this.parseHeader(block);
        case "!":
          return this.parseImage(block);
        //case "*":
        //  return this.parseList(block);
        default:
          return this.parseParagraph(block);
      }
    },
    parseHeader(block) {
      let size = block.match(/^[#]+/)[0].length;
      let heading = `h${size}`;

      let html = `<${heading} class="${this.$data.tags[heading].join(
        " "
      )}" style="color: #03B3F0">${block.match(/^(?:#+)(.+)/)[1]}</${heading}>`;

      return html;
    },
    parseParagraph(block) {
      let formatted = block;
      //console.log(block + block.length)

      //if(block.length <4)
      // return "";

      // formatBold
      let bold = /\*\*(.*?)\*\*/gm;
      formatted = formatted.replace(
        bold,
        `<span class="${this.$data.tags["bold"]}">$1</span>`
      );

      // formatItalics
      let italic = /\*(.*?)\*/gm;
      formatted = formatted.replace(
        italic,
        `<span class="${this.$data.tags["italic"]}">$1</span>`
      );

      // formatLinks
      let links = /\[(.*?)\]\((.*?)\)/gm;
      formatted = formatted.replace(
        links,
        `<a href="$2" class="${this.$data.tags["a"]}">$1</a>`
      );

      let html = `<p class="${this.$data.tags["p"].join(
        " "
      )}">${formatted}</p>`;
      return html;
    },
    parseImage(block) {
      const image = /!\[(.*?)\]\((.*?)\)/gm;
      let formatted = block.replace(
        image,
        `<img alt="$1" src="$2" />`
      );
      return formatted;
    },
    parseList(block) {
      return block;
    },
  },
};

</script>
