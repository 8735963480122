var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-card",
    { staticClass: "list-card " },
    [
      _c(
        "div",
        {
          staticClass: "text text-left mb-2 font-semibold",
          staticStyle: { color: "#03B3F0" }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._l(_vm.items, function(item) {
        return _c(
          "p",
          {
            key: item.value,
            staticClass: "text-sm font-light text-left text-gray-700 m-0"
          },
          [_vm._v(_vm._s(item.value))]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }