<template>
  <Component
    :is="tag"
    class="button"
    :class="[size, theme]"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </Component>
</template>

<script>
export default {
  name: "UiButton",
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "base",
    },
    theme: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.button {
  @apply inline-flex items-center border font-medium rounded transition ease-in-out duration-150 cursor-pointer;

  &:focus {
    @apply outline-none;
  }

  &[disabled] {
    @apply opacity-50 cursor-not-allowed;
  }
}

.xs {
  @apply px-2 py-1 text-xs leading-4;
}

.sm {
  @apply px-2 py-1 text-xs leading-4;
}

.md {
  @apply px-3 py-2 text-sm leading-4;
}

.base {
  @apply px-4 py-2 text-sm leading-5;
}

.lg {
  @apply px-4 py-2 text-base leading-6;
}

.xl {
  @apply px-6  py-3 text-base leading-6;
}

/* button themes */
.outline {
  @apply border-orange-600 text-orange-600 bg-transparent;

  &:hover {
    @apply text-orange-600 border-orange-600;
  }

  &:focus {
    @apply border-orange-700 shadow-outline;
  }

  &:active {
    @apply text-orange-700;
  }
}

.primary {
  @apply border-transparent text-white bg-orange-600;

  &:hover {
    @apply bg-orange-600;
  }

  &:focus {
    @apply border-orange-700 shadow-outline;
  }

  &:active {
    @apply bg-orange-700;
  }
}
</style>
