<template>
  <Component
    :is="tag"
    class="ui-card"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </Component>
</template>

<script>
//:class="[size, theme]"
//:disabled="disabled"
export default {
  name: "UiCard",
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
};
</script>

<style scoped>
.ui-card {
  @apply text-center rounded shadow px-4 py-5;
}
</style>
