var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "w-full max-w-screen-lg mx-auto pb-4" }, [
      _c("div", { staticClass: "flex justify-center -mt-20" }, [
        _c("img", {
          staticClass:
            "h-32 w-32 rounded-full border border-1 shadow-large z-10 bg-white",
          attrs: { src: "/logos/" + _vm.company.slug.toLowerCase() + ".png" }
        })
      ]),
      _c(
        "div",
        {
          staticClass:
            "grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 gap-8 my-8 px-3"
        },
        [
          _c(
            "div",
            { staticClass: "col-span-3" },
            [_c("markdown", { attrs: { content: _vm.company.blurb } })],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-1 mt-4" },
            [
              _c("social-links", {
                attrs: { links: _vm.content[_vm.company.slug].links }
              })
            ],
            1
          )
        ]
      )
    ]),
    _c("div", { staticStyle: { "background-color": "#f5f6f8" } }, [
      _c(
        "div",
        {
          staticClass:
            "grid xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-4 pt-12 max-w-screen-lg pb-4 gap-8 mx-auto px-3 lg:px-0"
        },
        _vm._l(_vm.content[_vm.company.slug].jobs, function(job) {
          return _c(
            "job-card",
            _vm._b(
              { key: job.title, staticClass: "bg-white" },
              "job-card",
              job,
              false
            )
          )
        }),
        1
      )
    ]),
    _c("div", { staticStyle: { "background-color": "#f5f6f8" } }, [
      _c("div", { staticClass: "w-full max-w-screen-lg mx-auto pt-1 pb-8" }, [
        _c(
          "div",
          {
            staticClass:
              "grid sm:grid-flow-row md:grid-flow-col md:grid-cols-4 gap-8 my-8"
          },
          [
            _c(
              "div",
              { staticClass: "md:col-span-3 bg-white px-6 pb-6 rounded" },
              [_c("markdown", { attrs: { content: _vm.company.content } })],
              1
            ),
            _c(
              "div",
              { staticClass: "md:col-span-1" },
              [
                _c("list-card", {
                  staticClass: "bg-white mb-10 w-full",
                  attrs: {
                    title: "Culture",
                    items: _vm.content[_vm.company.slug].culture
                  }
                }),
                _c("list-card", {
                  staticClass: "bg-white mb-10 w-full",
                  attrs: {
                    title: "Skills",
                    items: _vm.content[_vm.company.slug].skills
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }