<template>
  <div>
    <div
      class="relative pt-16 pb-32 flex content-center items-center justify-center"
      style="min-height: 45vh;"
    >
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        :style="{ 'background-image': 'url(' + getBannerImage() + ')' }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  methods: {
    getBannerImage() {
      const slug = this.$route.params.id ;
      console.log(`/images/${slug}/banner.jpg`)
      return `/images/${slug}/banner.jpg`;
    }
  }
};
//        style="background-image: url('https://images.unsplash.com/photo-1527192491265-7e15c55b1ed2?ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1267&amp;h=800&amp;q=80')"
</script>
