<template>
  <ui-card class="job-card">
    <div class="text-lg mb-2">
      {{ title }}
    </div>
    <p class="text-sm text-gray-700 m-0">
      {{ location }}
    </p>
    <p class="text-sm text-gray-700 pb-4 mt-0">
      {{ workType }}
    </p>
    <a
      :href="link"
    >
      <ui-button
        theme="primary"
        size="xs"
      >
        Apply
      </ui-button>
    </a>
  </ui-card>
</template>

<script>
import UiButton from "../UI/UiButton/UiButton";
import UiCard from "../UI/UiCard/UiCard";

export default {
  name: "JobCard",
  components: { UiButton, UiCard },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    workType: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
    }
  },
};
</script>

<style scoped>
.job-card {
  min-height: 165px;
  overflow: hidden;
}
</style>
