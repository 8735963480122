var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "relative pt-16 pb-32 flex content-center items-center justify-center",
        staticStyle: { "min-height": "45vh" }
      },
      [
        _c("div", {
          staticClass: "absolute top-0 w-full h-full bg-center bg-cover",
          style: { "background-image": "url(" + _vm.getBannerImage() + ")" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }